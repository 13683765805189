import { Box, Text, Flex, Link, Button } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
const Schedule = () => {
  return (
    <Flex direction="column" mt="6" minH="333px">
      <Text
        size="xs"
        align="center"
        color="gray.600"
        mb="4"
        mx="2"
        fontWeight="semibold"
      >
        График работы:
      </Text>
      <Box
        w="190px"
        alignSelf="center"
        align="center"
        borderWidth="1px"
        borderColor="gray.300"
        borderRadius="lg"
      >
        <Flex direction="row" color="green" fontWeight="medium">
          <Text w="50%" mr="1" ml="-2" align="end">
            Пн-Пт:
          </Text>

          <Text w="50%" ml="1" align="start">
            {' '}
            10 – 19
          </Text>
        </Flex>
        <Flex direction="row" color="red.400" fontWeight="medium">
          <Text w="50%" mr="1" ml="-2" align="end">
            Сб-Вс:
          </Text>

          <Text w="50%" ml="1" align="start">
            {' '}
            выходной
          </Text>
        </Flex>
      </Box>
      <Text
        fontWeight="semibold"
        size="xs"
        align="center"
        color="gray.600"
        mt="5"
        mb="1"
        mx="2"
      >
        Адрес:
      </Text>

      <Text
        w="40"
        alignSelf="center"
        align="center"
        bgColor="#99CC00"
        borderRadius="lg"
        color="white"
        fontWeight="semibold"
      >
        метро Борисово
      </Text>

      <Text size="xs" align="center" color="gray.600" mx="2">
        ул. Борисовские пруды, 8А, 2 этаж.
      </Text>

      <Button mt="4" variant="outline" bgColor="gray.200">
        <Link href="https://yandex.ru/maps/-/CCUy4DSfXB" isExternal>
          Открыть в Яндекс Картах <ExternalLinkIcon mx="2px" />
        </Link>
      </Button>
    </Flex>
  );
};

export default Schedule;
