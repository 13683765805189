import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Link,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import { WHATSAPP_ICON, INSTAGRAM_ICON, WEB_ICON, PHONE_ICON } from './icons';

const Faq = () => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="medium">
              Как сделать заказ?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={WEB_ICON} color="gray.700" />
              оформить на сайте{' '}
              <Link
                sx={{ textDecoration: 'underline' }}
                href="https://pora-v-roddom.ru/bags/?utm_source=instagram&utm_medium=social&utm_campaign=tap"
                color="brand.blue"
                isExternal
              >
                pora-v-roddom.ru <ExternalLinkIcon mx="2px" />
              </Link>
            </ListItem>
            <ListItem>
              <ListIcon as={PHONE_ICON} color="gray.700" />
              позвонить по телефону{' '}
              <Link
                sx={{ textDecoration: 'underline' }}
                href="tel:88007557712"
                color="brand.blue"
                isExternal
              >
                8(800)775-77-12
              </Link>
            </ListItem>
            <ListItem>
              <ListIcon as={WHATSAPP_ICON} color="gray.700" />
              написать в{' '}
              <Link
                sx={{ textDecoration: 'underline' }}
                href="https://wa.me/+79636047777"
                color="brand.blue"
                isExternal
              >
                WhatsApp
              </Link>
            </ListItem>
            <ListItem>
              <ListIcon as={INSTAGRAM_ICON} color="gray.700" />
              написать в{' '}
              <Link
                sx={{ textDecoration: 'underline' }}
                href="https://www.instagram.com/poravroddom/"
                color="brand.blue"
                isExternal
              >
                Директ
              </Link>
            </ListItem>
          </List>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="medium">
              Как оплатить заказ?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Если вы проживаете в Москве и Московской области, то заказ можно
          наличными курьеру или картой во время оформления заказа. А также в
          любой момент мы можем прислать ссылку для оплаты онлайн. Если вы
          проживаете в регионе и заказ передается в службу доставки СДЭК или
          Почту России, то оплатить заказ можно при оформлении заказа, или по
          ссылке на оплату после согласования с менеджером.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="medium">
              Сколько стоит доставка в мой город?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Рассчитать доставку в ваш город, вы можете на этапе оформления заказа.
          Для этого нужно ввести ваш адресс, и выбрать желаемый способ доставки.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="medium">
              Как быстро доставят мой заказ?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Ориентировочные сроки указаны на странице выбора способа доставки и
          зависят от службы доставки. После отправки заказа вы получите на почту
          трек-номер, по которому всегда сможете отследить местонахождение вашей
          посылки.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="medium">
              А в магазине мне помогут собрать сумку?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Конечно, наши менеджеры пройдут с вами по вашему или нашему списку и
          помогут подобрать все необходимое. Для удобства наш магазин разделен
          на зоны, где каждая соответсвуюет соответсвующему разделу списка в
          роддом.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="medium">
              У вас есть примерка?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Мы стараемся максимально помочь подобрать вещь чтобы она подошла без
          предварительной примерки. Если по каким-то причинам, вещи не подошли,
          то их можно вернуть удобным для вас способом. В некоторых случаях вещи
          можно заказать с примеркой, просто спросите об этом менеджера при
          подтверждении заказа.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontSize="lg" fontWeight="medium">
              Можно ли поменять или вернуть вещь, если она не подошла?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Да, все вещи, купленные у нас в магазине, можно вернуть или обменять в
          соответствии с законом "О защите прав потребителей"
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Faq;
