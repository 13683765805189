import { RiPercentFill } from '@react-icons/all-files/ri/RiPercentFill';
import { GiStorkDelivery } from '@react-icons/all-files/gi/GiStorkDelivery';
import { GoTasklist } from '@react-icons/all-files/go/GoTasklist';
import { IoBagCheck } from '@react-icons/all-files/io5/IoBagCheck';
import { IoIosHeart } from '@react-icons/all-files/io/IoIosHeart';
import { FaWhatsapp } from '@react-icons/all-files/fa/FaWhatsapp';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaTelegram } from '@react-icons/all-files/fa/FaTelegram';
import { AiOutlineGlobal } from '@react-icons/all-files/ai/AiOutlineGlobal';
import { AiOutlinePhone } from '@react-icons/all-files/ai/AiOutlinePhone';

export const BAG_ICON = IoBagCheck;
export const HEART_ICON = IoIosHeart;
export const PERCENT_ICON = RiPercentFill;
export const STORK_DELIVERY_ICON = GiStorkDelivery;
export const TASKL_LIST_ICON = GoTasklist;
export const WHATSAPP_ICON = FaWhatsapp;
export const INSTAGRAM_ICON = FaInstagram;
export const TELEGRAM_ICON = FaTelegram;
export const WEB_ICON = AiOutlineGlobal;
export const PHONE_ICON = AiOutlinePhone;
