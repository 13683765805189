// import main from './main.jpg';
import image0 from './00.jpg';
import image1 from './01.jpg';
import image2 from './02.jpg';
import image3 from './03.jpg';
import image4 from './04.jpg';
import image5 from './05.jpg';
import image6 from './06.jpg';
import image7 from './07.jpg';
import image8 from './08.jpg';
import image9 from './09.jpg';
import image10 from './10.jpg';

export const mainImg = image0;
export const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];
