import React from 'react';
import {
  Text,
  Heading,
  Center,
  Flex,
  Image,
  HStack,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';

import {
  BAG_ICON,
  HEART_ICON,
  PERCENT_ICON,
  STORK_DELIVERY_ICON,
  TASKL_LIST_ICON,
} from '../src/components/icons';

import logo from './images/logo.png';
import ozon_logo from './images/ozon_logo.svg';
import Button from './components/Button';
import Divider from './components/Divider';
import SwiperX from './components/Swiper';
import Faq from './components/Faq';
import Social from './components/Social';

function App() {
  return (
    <Center>
      <Flex direction="column" minW="xs" maxW="sm" color="gray.700">
        <Image alignSelf="center" src={logo} w="38%" alt="logo" my="6" />
        <Heading as="h2" size="md" align="center" color="gray.600">
          Магазин-эксперт по сборам в роддом
        </Heading>
        <Text size="xs" align="center" color="gray.600" my="4" mx="2">
          Мы работаем с 2015 года. И, пожалуй, знаем все про сборы в роддом.
        </Text>
        <LinkBox>
          <HStack
            mx="2"
            mt="2"
            mb="6"
            justify="center"
            borderWidth="1px"
            rounded="md"
            p="4"
            borderRadius="full"
            shadow="md"
            boredrColor="#001A34"
          >
            <Heading as="h3" size="lg" color="#001A34" opacity={'70%'}>
              Купить на{' '}
            </Heading>
            <LinkOverlay href="https://www.ozon.ru/brand/pora-v-roddom-100140613/"></LinkOverlay>
            <Image
              alignSelf="center"
              src={ozon_logo}
              w="38%"
              alt="logo"
              my="6"
            />
          </HStack>
        </LinkBox>
        <Button
          icon={BAG_ICON}
          title={'ВЫБРАТЬ ГОТОВУЮ СУМКУ'}
          bgColor={'brand.blue'}
          linkTo={
            'https://pora-v-roddom.ru/bags/?utm_source=instagram&utm_medium=social&utm_campaign=tap'
          }
        />

        <Button
          icon={HEART_ICON}
          title={'СОБРАТЬ СВОЮ СУМКУ'}
          bgColor={'brand.pink'}
          linkTo={
            'https://pora-v-roddom.ru/build/?utm_source=instagram&utm_medium=social&utm_campaign=tap'
          }
        />

        <Divider />

        <Button
          icon={TASKL_LIST_ICON}
          title={'СКАЧАТЬ ЧЕК-ЛИСТ В РОДДОМ'}
          bgColor={'brand.yellow'}
          linkTo={'/pvr_ChL_A4_new.pdf'}
        />

        <Divider />

        <Button
          icon={STORK_DELIVERY_ICON}
          title={'ДОСТАВКА ПО ВСЕЙ РОССИИ'}
          bgColor={'brand.green'}
          linkTo={
            'https://pora-v-roddom.ru/delivery?utm_source=instagram&utm_medium=social&utm_campaign=tap'
          }
        />

        <Button
          icon={PERCENT_ICON}
          title={' ПОДАРКИ И АКЦИИ'}
          bgColor={'brand.green'}
          linkTo={
            'https://pora-v-roddom.ru/products/discounts?utm_source=instagram&utm_medium=social&utm_campaign=tap'
          }
        />

        <Heading as="h2" size="md" align="center" color="gray.600" my="4">
          Магазин в Москве
        </Heading>

        <Heading as="h3" size="md" align="center" color="red.400" my="4">
          Суббота и воскресенье — выходные!
        </Heading>

        <SwiperX />
        <Heading as="h2" size="md" align="center" color="gray.600" my="4">
          Часто задаваемые вопросы
        </Heading>
        <Faq />
        <Heading as="h2" size="md" align="center" color="gray.600" my="5">
          Напишите нам
        </Heading>
        <Social />
      </Flex>
    </Center>
  );
}

export default App;
