import { Flex, IconButton, Spacer } from '@chakra-ui/react';
import { WHATSAPP_ICON, INSTAGRAM_ICON, TELEGRAM_ICON } from './icons';

const Social = () => {
  return (
    <Flex direction="row" mx="12" mt="3" mb="9">
      <IconButton
        as="a"
        href="https://wa.me/+79636047777"
        colorScheme="whatsapp"
        aria-label="Open Whatsapp"
        boxSize="60px"
        fontSize="40px"
        opacity="90%"
        shadow="lg"
        isRound
        icon={<WHATSAPP_ICON />}
      />
      <Spacer />
      <IconButton
        as="a"
        href="https://www.instagram.com/poravroddom/"
        bgColor="#8a3ab9"
        color="white"
        aria-label="Open Instagram"
        boxSize="60px"
        fontSize="40px"
        opacity="90%"
        shadow="lg"
        isRound
        icon={<INSTAGRAM_ICON />}
      />
      <Spacer />
      <IconButton
        as="a"
        href="https://t.me/poravroddom"
        colorScheme="telegram"
        aria-label="Open Telegram"
        boxSize="60px"
        fontSize="40px"
        opacity="90%"
        shadow="lg"
        isRound
        icon={<TELEGRAM_ICON />}
      />
    </Flex>
  );
};

export default Social;
