import { Box, Image } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { mainImg, images } from '../images/shop/index';

import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';

import 'swiper/css/bundle';
import Schedule from './Schedule';
import SwiperCore, { Pagination } from 'swiper';

SwiperCore.use([Pagination]);

export default function SwiperX() {
  return (
    <Box height="100%" w="100%" align="center" px="6">
      <Swiper
        pagination={true}
        spaceBetween={30}
        loop={true}
        className="mySwiper"
      >
        {/* На первом слайде заглавная фотка */}
        <SwiperSlide>
          <Image
            alignSelf="center"
            src={mainImg}
            alt="logo"
            mb="40px"
            borderRadius="lg"
          />
        </SwiperSlide>
        {/* На втором месте график работы */}
        <SwiperSlide>
          <Schedule />
        </SwiperSlide>
        {/* Грузим остальные фотки */}
        {images.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <Image
                alignSelf="center"
                src={img}
                alt="logo"
                mb="40px"
                borderRadius="lg"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
