import {
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  Center,
  HStack,
} from '@chakra-ui/react';

const Button = ({ bgColor, title, icon, linkTo }) => {
  return (
    <LinkBox
      as="button"
      borderRadius="full"
      bg={bgColor}
      opacity="70%"
      color="white"
      mx="2"
      p="3"
      fontSize="1.1em"
      fontWeight="semibold"
      mb="4"
      shadow="lg"
    >
      <HStack>
        <Center>
          <Icon as={icon} w={8} h={8} ml="2" />
          <Text mt="1" ml="3">
            <LinkOverlay href={linkTo}>{title}</LinkOverlay>
          </Text>
        </Center>
      </HStack>
    </LinkBox>
  );
};

export default Button;
